

//global slide up effect animation
var win = $(window);
var allMods = $(".fade-default");

win.scroll(function (event) {
    allMods.each(function (i, el) {
        var el = $(el);
        //if (el.visible(true)) {
        if (el.is(':visible')) {
            var fadeClass = "";
            if (typeof el.attr("data-fade-class") !== "undefined") {
                var fadeClass = " " + el.attr("data-fade-class");
            }
            el.addClass("come-in" + fadeClass);
        }
    });

});


$(window).trigger('scroll');
        //global slide up effect animation